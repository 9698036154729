/*LISTS COLLECTION*/
import { buildCollection, EntityReference } from "firecms";

type List = {
  title: string;
  description: string;
  posts: EntityReference[];
  users: EntityReference[];
  itemsFeatured1: EntityReference[];
  itemsFeatured2: EntityReference[];
  items: EntityReference[];
  itemGroups: Map<String,EntityReference[]>;
  combinations: EntityReference[];
}

export const lists = buildCollection<List>({
  name: "Lists",
  icon: "FolderSpecial",
  group: "Managed Content",
  singularName: "List",
  path: "lists",
  customId: true,
  textSearchEnabled: false,
  permissions: ({ authController }) => ({
    edit: true,
    create: true,
    delete: false,
  }),
  properties: {
    title: {
      name: "Title",
      dataType: "string",
      validation: { required: true },
    },
    description: {
      name: "Description",
      dataType: "string",
      multiline: true,
    },
    posts: {
      name: "Posts",
      description: "Choose the posts for this list",
      dataType: "array",
      of: {
        dataType: "reference",
        path: "posts",
      },
    },
    users: {
      name: "Users",
      description: "Choose the users for this list",
      dataType: "array",
      of: {
        dataType: "reference",
        path: "users",
      },
    },
    itemsFeatured1: {
      name: "Items Featured 1",
      description: "Choose the items for this list",
      dataType: "array",
      of: {
        dataType: "reference",
        path: "items",
      },
    },
    itemsFeatured2: {
      name: "Items Featured 2",
      description: "Choose the items for this list",
      dataType: "array",
      of: {
        dataType: "reference",
        path: "items",
      },
    },
    items: {
      name: "Items",
      description: "Choose the items for this list",
      dataType: "array",
      of: {
        dataType: "reference",
        path: "items",
      },
    },
    itemGroups: {
      name: "Item Groups",
      dataType: "map",
      keyValue: true,
    },
    combinations: {
      name: "Combinations",
      description: "Choose the combination posts for this list",
      dataType: "array",
      of: {
        dataType: "reference",
        path: "posts",
      },
    },
  },
});